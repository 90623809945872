import React from 'react'
import {Link} from 'gatsby'
import {Layout, SEO} from '@components'

const AboutPage = () => (
  <Layout>
    <SEO title="Who am I?" />
    <h1 className="txt-center">Who am I?</h1>
    <p>
      I am someone who is driven by a passion to create meaningful experiences
      and understand what is possible.
    </p>

    <p>
      In 2003, my brother and I were driven by a love for live music. I designed
      and built my first website for us to write music reviews because we wanted
      to go to concerts for free and meet our favorite musicians. We spent years
      in that space exploring ways to connect people through those experiences
      and while I don't actively work in that space today, I do still believe in
      music's power to influence people.
    </p>

    <p>
      In 2008, I was driven to have an impact on businesses and organizations.
      I’ve spent more than a decade designing and building influential campaign
      sites, e-commerce, and organizational websites. I continue to do so today.
    </p>

    <p>
      In 2014, I was driven to understand the world from my own first-hand
      experience. I put my laptop and client work in a backpack and spent the
      next five years only setting foot on American soil to physically reconnect
      with my family, friends, and clients.
    </p>

    <p>
      In 2019, I was driven to continue growing as a designer and developer. I
      attended Lambda School to level up my skills and collaborated with
      cross-functional teams of designers, developers, and data scientists to
      build modern full-stack applications across a wide variety of projects.
    </p>

    <p>
      Around the same time, I was driven to a leadership role at Lambda School
      and have spent almost every day of the last year mentoring full-stack web
      developers, doing code reviews, solving challenges unique to each student,
      and helping them build confidence in their own skills and abilities.
    </p>

    <p>Today, I continue to follow my passions.</p>

    <p className="flush--bottom">
      I am seeking a full-time opportunity to collaborate and create meaningful
      experiences on a cross-functional team that cares deeply about the work
      they do.
    </p>
  </Layout>
)

export default AboutPage
